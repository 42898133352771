<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline label-width="88px">
                    <el-row>
                        <el-col :span="24"></el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border>
                    <el-table-column label="日期" prop="bill_date_tip"></el-table-column>
                    <el-table-column label="账户" prop="account_name"></el-table-column>
                    <el-table-column label="标题" prop="bill_name"></el-table-column>
                    <el-table-column label="出账单" v-if="false">
                        <template #default="scope">
                            <el-tag v-if="scope.row.is_end === false" type="danger">未出</el-tag>
                            <el-tag v-if="scope.row.is_end === true" type="success">已出</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="收入(元)" prop="income" align="right">
                        <template #default="scope">
                            <div style="text-align: right">
                                <div style="color:#409EFF;font-weight: bold">+ {{scope.row.income}}</div>
                                <div>{{scope.row.income_count}}笔</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="支出(元)" prop="outcome" align="right">
                        <template #default="scope">
                            <div style="text-align: right">
                                <div style="color:#F56C6C;font-weight: bold">- {{scope.row.outcome}}</div>
                                <div>{{scope.row.outcome_count}}笔</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <a :href="'/#/b/balance-bill-detail?id='+scope.row.id" target="_blank" style="margin-right:10px;color:#409EFF;">详情</a>
<!--                            <el-button type="text" size="" @click="onExportTap(scope.row)">导出报表</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

    </div>
</template>

<script>
    import {getParams} from "@/api/hado/param";
    import {exportExcel, getList} from "@/api/hado/balance-bill";
    import {formatDate, formatTimestamp} from "@/utils/time";
    const param_list=['balance_record_flag','balance_record_type','balance_bill_cycle_type']
    export default {
        name: "balance-bill-list",
        data(){
            return{
                loading:false,
                search_form_name:'balance_bill_search',
                page_option:{page:1,size:10,total:0},
                search_option:{time_range:'all',play_type:'all'},
                page_list:[],
                params:{playground_type:[],},
                params_map_pure:{},
                params_map:{recharge_type:{},},
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage() {
                this.initParams().then(() => {
                    this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams() {
                return new Promise((resolve, reject) => {
                    getParams(param_list.join(','), 'en').then(resp => {
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl => {
                            console.log('pl:', pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm => {
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key] = pm.value
                                tmp_map[pm.key] = {key: pm.key, value: pm.value, color: pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value] = pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        resolve(resp)
                    }).catch((err) => {
                        if (err) {
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt, searchOpt) {
                this.loading = true;
                getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
                    this.loading = false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(() => {
                    this.loading = false;
                })
            },
            handleSearchTap() {
                let searchOpt = Object.assign({}, this.search_option)
                console.log(searchOpt.time_range)
                if(this.search_option.time_range&&Array.isArray( this.search_option.time_range)){
                    searchOpt.time_range = formatDate(searchOpt.time_range[0])+'||'+formatDate( searchOpt.time_range[1])
                }
                if(this.search_option.record_time_range&&Array.isArray( this.search_option.record_time_range)){
                    searchOpt.record_time_range = formatDate(searchOpt.record_time_range[0])+'||'+formatDate( searchOpt.record_time_range[1])
                }
                if(this.search_option.order_time_range&&Array.isArray( this.search_option.order_time_range)){
                    searchOpt.order_time_range = formatDate(searchOpt.order_time_range[0])+'||'+formatDate( searchOpt.order_time_range[1])
                }
                this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
            },
            handleSearchClear(formName) {
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize) {
                this.getPageList({page: 1, size: pageSize}, this.search_option);
            },
            handleCurrentChange(pageIndex) {
                this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            onExportTap(row){
                exportExcel(row.id).then(res=>{
                    const blob = new Blob([res.data]);//new Blob([res])中不加data就会返回下图中[objece objece]内容（少取一层）
                    console.log('blob:',blob)
                    if(res.data.type!=="application/force-download"){
                        // console.log(res)
                        console.log("文件类型不对:",blob.type)
                        this.$message.warning("你无法下载订单")
                        return
                    }
                    const fileName = 'hado_bill_'+row.bill_date_tip+ '.xlsx';//下载文件名称

                    const elink = document.createElement('a');
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                }).catch(err=>{
                    this.$message.error("下载异常")
                    console.log("catch:",err)
                })
            }
        }
    }
</script>

<style scoped>

</style>
